import { authRequest } from "../../utils/request/authRequest";

export interface UserInterface {
    profile: {
      avatarUrl: string;
      birthdate: string;
      firstName: string;
      lastName: string;
      bio: string;
      description: string;
    };
    id: string;
    email: string;
    username: string;
    error?: any;
}
  
export interface UserInterfaceInfo extends UserInterface {
    roles: string[];
    walletAddresses: string[];
    referralCode: string;
}

export const getUser = async (id: string): Promise<UserInterfaceInfo> => {
    const response = await authRequest(`v1/users/` + id, {
        method: "GET",
    });
    return response.data;
};
