import { useLayoutEffect, useRef } from "react";
import ReactSelect, { components, OptionProps } from "react-select";

import { TablerIcon } from "@/components/atoms";
import { cn } from "@/lib/utils";
import { IconName } from "@/types/icons";

import { ReactSelectOption, SelectOption, SelectProps } from "./types";

export function Select({
  defaultValue = null,
  id,
  onChange,
  options,
  placeholder,
}: SelectProps) {
  const handleChange = (option: ReactSelectOption) => {
    onChange?.(option);
  };

  return (
    <ReactSelect
      options={options}
      onChange={(selectedOption) => {
        if (Array.isArray(selectedOption)) {
          throw new Error(
            "Unexpected type passed to ReactSelect onChange handler",
          );
        }

        handleChange(selectedOption);
      }}
      placeholder={placeholder}
      id={id}
      components={{ Option: IconOption, DropdownIndicator: Indicator }}
      defaultValue={defaultValue}
      className="w-full flex-1"
      classNames={{
        control: (state) =>
          cn(
            "border rounded-lg border-gray-300 bg-gray-50 text-base text-gray-900 placeholder:text-gray-400 py-2.5 px-3 h-10 hover:border-gray-400",
            state.menuIsOpen && "bg-white rounded-b-none border-gray-400",
          ),
        menuList: () =>
          "bg-white border-x border-b rounded-b-lg border-gray-300 p-2 text-base",
        option: (state) => {
          return cn(
            "rounded-[4px] p-2 hover:bg-gray-50",
            state.isSelected && "bg-indigo-50 hover:bg-indigo-50",
          );
        },
        placeholder: () => "text-gray-400",
        dropdownIndicator: (state) => {
          return cn(
            "text-gray-400",
            (state.isFocused || state.hasValue) && "text-gray-500",
          );
        },
      }}
      unstyled
    />
  );
}

const { Option, DropdownIndicator } = components;

const IconOption = (
  props: OptionProps<{ label: string; value: string; icon?: IconName }>,
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const icon = props.data.icon;

  useLayoutEffect(() => {
    props.isSelected &&
      ref.current?.scrollIntoView({ behavior: "instant", block: "center" });
  }, [props.isSelected]);

  const refCallback = (node: HTMLDivElement) => {
    ref.current = node;
  };

  return (
    <Option {...props} innerRef={refCallback}>
      {icon && <TablerIcon name={icon} />}

      {props.data.label}
    </Option>
  );
};

const Indicator = (props: any) => {
  return (
    <DropdownIndicator {...props}>
      <TablerIcon name="chevron-down" className="h-4 w-4" />
    </DropdownIndicator>
  );
};
